<template>
  <div class="input-group">
    <span class="input-group-text">Anzahl</span>
    <input @change="setValue" type="number" class="form-control form-control-sm" min="1" max="50" :value="item.quantity">
  </div>
</template>
<script>
import { computed, toRefs } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  props: {
    itemId: {
      required: true,
      default: 1
    }
  },
  setup (props) {
    const store = useStore()
    const { itemId } = toRefs(props)

    const item = computed(() => {
      return store.getters['checkout/items'].find(i => i.id === itemId.value)
    })

    function setValue (e) {
      const qty = Number.parseInt(e.target.value)
      if (qty > 0) {
        item.value.quantity = qty
        store.dispatch('checkout/changeQty', item.value.id)
      }
    }
    return {
      setValue,
      item
    }
  }
}
</script>
